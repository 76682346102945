.my-card {
    max-width: 500px;
    margin: 0 auto;
 /*  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%),
    0 5px 5px -3px rgb(0 0 0 / 30%); */
}

.my-card-img-container {
  height: 330px;
  width: 100%;
  border-radius: 9px 9px 0px 0px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}
.my-card-img-container:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 9px 9px 0px 0px;
}

.my-card-img {
  border-radius: 9px 9px 0px 0px;
  position: absolute;
  display: block;
  height: 330px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.my-card-info {
  padding: 1rem 1rem;
}

.card-gift {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.chips {
  display: inline-block;
  border: 1px solid #e7e7e7;
  border-radius: 20px;
  padding: 0.2rem 1rem;
  margin-right: 0.4rem;
  background-color: #efefef;
}
