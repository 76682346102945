.t-card {
  position: relative;
  background-color: #fff;
  width: 600px;
  padding: 20px;
  max-width: 85vw;
  height: 50vh;

  border-radius: 20px;
  background-size: cover;
  background-position: center;
  /* box-shadow: 0px 10px 53px 0px rgba(0, 0, 0, 0.3); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: translate(0, 33px) scale(0.9, 0.9);
  pointer-events: none;
}

.tinderCards__cardContainer {
  display: flex;
  justify-content: center;
  margin-top: 11vh;
}

.swipe {
  cursor: pointer;
  position: absolute;
}

.top-card {
  transform: translate(0, 0) scale(1, 1);
  transition: all 0.3s;
  box-shadow: 0px 10px 53px 0px rgba(0, 0, 0, 0.3);
}

.card-before {
  box-shadow: 0px 10px 53px 0px rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

.fake-card {
  position: absolute;
  z-index: -200;
}

.card-blur {
  filter: blur(10px);
}

.top-card-unblur {
  filter: blur(0px);
  transition: all 0.3s;
}

.cardContent {
  width: 100%;
  height: 100%;
}

.card h3 {
  text-align: center;
  color: rgb(66, 66, 66);
  font-size: 1.7rem;
  /*   word-break: break-word; */
  /* word-break: keep-all; */
  /* font-size: 5vw; */
}

@media only screen and (max-height: 668px) {
  .tinderCards__cardContainer {
    margin-top: 7.5vh;
  }
}
@media only screen and (max-height: 380px) {
  .tinderCards__cardContainer {
    margin-top: 6vh;
  }
}
@media only screen and (max-height: 365px) {
  .tinderCards__cardContainer {
    margin-top: 5vh;
  }
}
@media only screen and (max-height: 340px) {
  .tinderCards__cardContainer {
    margin-top: 3vh;
  }
}
