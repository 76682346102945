.product {
  cursor: pointer;
  height: 7.5rem;
  border: 1px solid grey;
  border-radius: 9px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  overflow-wrap: anywhere;
}
.product:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 9px;
}

.product h6 {
  color: white;
  font-weight: bold;
  z-index: 100;
  text-align: center;
}

.product h2 {
  color: white;
  font-weight: bold;
  z-index: 100;
  text-align: center;
  margin-bottom: 0;
}

.product-price {
  color: white;
  font-weight: bold;
  z-index: 3;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 0 4px;
  background-color: #008001;
  font-size: 13px;
  text-align: center;
}

.product-price-big {
  margin-bottom: 0.8rem;
  font-size: 1rem;
}

.product-selected {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 100;
  color: white;
}
