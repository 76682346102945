.team {
  height: 9rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 1.5rem;
  width: 100%;
  max-width: 9rem;
  max-height: 9rem;
  border-radius: 50%;
  background-color: aqua;
  border: 5px solid lightcyan !important;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.del-team {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  right: 0;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid lightcyan;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.teams {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.5rem;
}

.plus {
  color: tomato;
  font-size: 250%;
}
.cross {
  color: tomato;
}

.team-name-container {
  min-width: 0;
  flex: 1;
  display: flex;
  flex-flow: column;
}

.team-name {
  line-height: 1.4;
  margin-top: -7px;
  font-weight: bold;
  word-wrap: break-word;
  max-width: 120px;
}

.members-names-score-screen {
  word-wrap: break-word;
  /* max-width: 220px; */
  max-width: 70vw;
  margin-left: 1rem;
}

.avatar-img {
  width: 50%;
}

.team-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 310px) {
  .team {
    max-width: 7rem;
    max-height: 7rem;
  }
}
