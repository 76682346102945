/* * {
  --landing-bg-color: #eff4ff;
} */
.footer-color {
  background-color: var(--landing-footer-bg-color);
}
.footer-dark-color {
  background-color: #c3d5fe;
}

.footer-copyright {
  margin-bottom: -1rem;
}

.landing-theme-color {
  background-color: var(--landing-bg-color);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.landing-navbar {
  width: 100%;
  padding: 1rem;
  margin: -1rem 0 0 0;
}

.landing-navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1100px;
}

.landing-nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-side {
  padding: 9%;
  width: 50%;
}

.right-side {
  width: 50%;
}

.left-side-rule {
  width: 50%;
}

.right-side-rule {
  width: 50%;
}

.rule-text {
  padding: 0 2.5rem;
  font-size: 1rem;
}

.header-landing {
  margin-top: 3.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.alias-logo {
  margin: 0 auto;
  width: 100%;
}

.main-pic {
  margin: 0 auto;
  width: 100%;
  max-width: 560px;
}

.divider-container-top {
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: 100px;
}

.divider-container-bottom {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}

.custom-shape-divider-bottom-1641690437 {
  position: absolute;
  top: -90px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1641690437 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 90px;
}

.custom-shape-divider-bottom-1641690437 .shape-fill {
  fill: var(--landing-bg-color);
}

.custom-shape-divider-bottom-1641690437 .shape-fill-footer {
  fill: var(--landing-footer-bg-color);
}

.rules {
  padding: 2rem 0;
}

.custom-shape-divider-top-1641691634 {
  position: absolute;
  bottom: -100px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1641691634 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100px;
}

.custom-shape-divider-top-1641691634 .shape-fill {
  fill: var(--landing-bg-color);
}

.play-section {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  /* height: 300px; */
  flex-direction: column;
  padding-bottom: 5.3rem;
}

.install-app {
  display: flex;
  justify-content: space-between;
}

.install-app-left {
  align-self: center;
  width: 55%;
}

.quirks {
  margin: 5rem 0 3rem 0;
}

.bullits {
  margin: 3rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.bullit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bullit-img {
  width: 150px;
}

.bullit h6 {
  padding-top: 3rem;
  line-height: 1.5rem;
}

.start-landing-btn {
  width: 40%;
  min-width: 110px;
}

.cybr-btn {
  --primary: hsl(
    var(--primary-hue),
    85%,
    calc(var(--primary-lightness, 50) * 1%)
  );
  --shadow-primary: hsl(var(--shadow-primary-hue), 90%, 50%);
  --primary-hue: 0;
  --primary-lightness: 50;
  --color: hsl(0, 0%, 100%);
  --font-size: 26px;
  --shadow-primary-hue: 180;
  --label-size: 9px;
  --shadow-secondary-hue: 60;
  --shadow-secondary: hsl(var(--shadow-secondary-hue), 90%, 60%);
  --clip: polygon(
    0 0,
    100% 0,
    100% 100%,
    95% 100%,
    95% 90%,
    85% 90%,
    85% 100%,
    8% 100%,
    0 70%
  );
  --border: 4px;
  --shimmy-distance: 5;
  --clip-one: polygon(
    0 2%,
    100% 2%,
    100% 95%,
    95% 95%,
    95% 90%,
    85% 90%,
    85% 95%,
    8% 95%,
    0 70%
  );
  --clip-two: polygon(
    0 78%,
    100% 78%,
    100% 100%,
    95% 100%,
    95% 90%,
    85% 90%,
    85% 100%,
    8% 100%,
    0 78%
  );
  --clip-three: polygon(
    0 44%,
    100% 44%,
    100% 54%,
    95% 54%,
    95% 54%,
    85% 54%,
    85% 54%,
    8% 54%,
    0 54%
  );
  --clip-four: polygon(
    0 0,
    100% 0,
    100% 0,
    95% 0,
    95% 0,
    85% 0,
    85% 0,
    8% 0,
    0 0
  );
  --clip-five: polygon(
    0 0,
    100% 0,
    100% 0,
    95% 0,
    95% 0,
    85% 0,
    85% 0,
    8% 0,
    0 0
  );
  --clip-six: polygon(
    0 40%,
    100% 40%,
    100% 85%,
    95% 85%,
    95% 85%,
    85% 85%,
    85% 85%,
    8% 85%,
    0 70%
  );
  --clip-seven: polygon(
    0 63%,
    100% 63%,
    100% 80%,
    95% 80%,
    95% 80%,
    85% 80%,
    85% 80%,
    8% 80%,
    0 70%
  );
  font-family: "Cyber", sans-serif;
  color: var(--color);
  cursor: pointer;
  background: transparent;
  text-transform: uppercase;
  font-size: var(--font-size);
  outline: transparent;
  letter-spacing: 2px;
  position: relative;
  font-weight: 700;
  border: 0;
  width: 100%;
  max-width: 250px;
  height: 75px;
  line-height: 75px;
  transition: background 0.2s;
}

.cybr-btn:hover {
  --primary: hsl(
    var(--primary-hue),
    85%,
    calc(var(--primary-lightness, 50) * 0.8%)
  );
}
.cybr-btn:active {
  --primary: hsl(
    var(--primary-hue),
    85%,
    calc(var(--primary-lightness, 50) * 0.6%)
  );
}

.cybr-btn:after,
.cybr-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  clip-path: var(--clip);
  z-index: -1;
}

.cybr-btn:before {
  background: var(--shadow-primary);
  transform: translate(var(--border), 0);
}

.cybr-btn:after {
  /*  background: var(--primary); */
  background: #00bfff;
}

.cybr-btn__tag {
  position: absolute;
  /*  padding: 1px 5px; */
  letter-spacing: 1px;
  line-height: 1;
  bottom: -5%;
  right: 5%;
  font-weight: normal;
  color: hsl(0, 0%, 0%);
  font-size: var(--label-size);
}

.cybr-btn__glitch {
  position: absolute;
  top: calc(var(--border) * -1);
  left: calc(var(--border) * -1);
  right: calc(var(--border) * -1);
  bottom: calc(var(--border) * -1);
  /* background: var(--shadow-primary); */
  background: #00bfff;
  text-shadow: 2px 2px var(--shadow-primary), -2px -2px var(--shadow-secondary);
  clip-path: var(--clip);
  animation: glitch 2s infinite;
  display: none;
}

.cybr-btn .cybr-btn__glitch {
  display: block;
}

.cybr-btn__glitch:before {
  content: "";
  position: absolute;
  top: calc(var(--border) * 1);
  right: calc(var(--border) * 1);
  bottom: calc(var(--border) * 1);
  left: calc(var(--border) * 1);
  clip-path: var(--clip);
  /* background: var(--primary); */
  background: #00bfff;
  z-index: -1;
}

@keyframes glitch {
  0% {
    clip-path: var(--clip-one);
  }
  2%,
  8% {
    clip-path: var(--clip-two);
    transform: translate(calc(var(--shimmy-distance) * -1%), 0);
  }
  6% {
    clip-path: var(--clip-two);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }
  9% {
    clip-path: var(--clip-two);
    transform: translate(0, 0);
  }
  10% {
    clip-path: var(--clip-three);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }
  13% {
    clip-path: var(--clip-three);
    transform: translate(0, 0);
  }
  14%,
  21% {
    clip-path: var(--clip-four);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }
  25% {
    clip-path: var(--clip-five);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }
  30% {
    clip-path: var(--clip-five);
    transform: translate(calc(var(--shimmy-distance) * -1%), 0);
  }
  35%,
  45% {
    clip-path: var(--clip-six);
    transform: translate(calc(var(--shimmy-distance) * -1%));
  }
  40% {
    clip-path: var(--clip-six);
    transform: translate(calc(var(--shimmy-distance) * 1%));
  }
  50% {
    clip-path: var(--clip-six);
    transform: translate(0, 0);
  }
  55% {
    clip-path: var(--clip-seven);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }
  60% {
    clip-path: var(--clip-seven);
    transform: translate(0, 0);
  }
  31%,
  61%,
  100% {
    clip-path: var(--clip-four);
  }
}

.cybr-btn:nth-of-type(2) {
  --primary-hue: 260;
}

@media only screen and (max-width: 600px) {
  .header-landing {
    margin-top: 1rem;
    flex-direction: column;
    height: auto;
  }
  .landing-navbar{
    padding: 0.5rem 1rem;
  }
  .left-side {
    order: 2;
    width: 100%;
    padding: 0;
  }

  .right-side {
    order: 1;
    width: 80%;
    margin-left: 10%;
    padding-bottom: 2rem;
  }

  .alias-logo {
    margin: 0 auto;
    width: 60%;
  }

  .landing-btn {
    width: 60%;
    min-width: 110px;
  }

  .mobile-flex-direction {
    flex-direction: column;
  }

  .left-side-rule {
    width: 100%;
  }
  .right-side-rule {
    width: 100%;
  }

  .mobile-order-1 {
    order: 1;
  }
  .mobile-order-2 {
    order: 2;
  }
  .rule-text {
    padding: 0 1rem;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }

  .install-app {
    flex-direction: column;
  }

  .install-app-left {
    width: 100%;
  }

  .quirks {
    margin: 1rem 0 0 0;
  }

  .bullits {
    margin-top: 1rem;
    flex-direction: column;
  }
  .bullit h6 {
    padding-top: 1rem;
  }

  .bullit-header {
    padding-top: 1rem;
    padding-bottom: 2.5rem;
    line-height: 1.2rem;
  }

  .bullit-last-header {
    padding-top: 1rem;
    padding-bottom: 0;
    line-height: 1.2rem;
  }

  /* .bullit h6:last-child {
    padding-bottom: 0;
  } */

  .bullit-img {
    width: 150px;
  }
}

@media only screen and (max-width: 303px) {
  .landing-navbar-content {
    flex-direction: column;
  }
  .navbar-brand {
    display: none;
  }
}

