.three-grid-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.select-color {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px !important;
  height: 60px !important;
}

.edit-avatars {
  margin: 0 auto;
  padding: 0.5rem 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-avatar {
  width: 4.5rem;
  height: 4.5rem;
  background-color: rgb(255, 99, 71);
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}

.form-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.5rem + 0px);
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #55595c;
  background-color: #f7f7f9;
  background-clip: padding-box;
  border: 0px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-input:focus {
  color: #55595c;
  background-color: #f7f7f9;
  border-color: #5a5a5a;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(26 26 26 / 25%);
}

.td-name {
  word-wrap: break-word;
  max-width: 30vw;
}

.animation-tomato {
  background-color: rgb(255, 99, 71);
  animation: pulse-tomato 2s infinite;
}

.animation-blue {
  background-color: rgb(0, 150, 255);
  animation: pulse-blue 2s infinite;
}

.animation-iris {
  background-color: rgb(93, 63, 211);
  animation: pulse-iris 2s infinite;
}

.animation-jade {
  background-color: rgb(0, 163, 108);
  animation: pulse-jade 2s infinite;
}

.animation-orange {
  background-color: rgb(255, 173, 5);
  animation: pulse-orange 2s infinite;
}

.animation-lemon {
  background-color: rgb(252, 255, 75);
  animation: pulse-lemon 2s infinite;
}

.animation-dark-blue {
  background-color: rgb(4, 67, 137);
  animation: pulse-dark-blue 2s infinite;
}

.animation-pink {
  background-color: rgb(246, 82, 160);
  animation: pulse-pink 2s infinite;
}

.animation-cyan {
  background-color: rgb(54, 238, 224);
  animation: pulse-cyan 2s infinite;
}

.simple-animation {
  animation: pulse 2s infinite;
}
/* linear-gradient(#262626, #141414); */

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 99, 71, 1);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 99, 71, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 99, 71, 0);
  }
}

@keyframes pulse-tomato {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(255, 99, 71, 0.5);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 99, 71, 0);
  }

  100% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(255, 99, 71, 0);
  }
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(0, 150, 255, 0.5);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 150, 255, 0);
  }

  100% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(0, 150, 255, 0);
  }
}

@keyframes pulse-iris {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(93, 63, 211, 0.5);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(93, 63, 211, 0);
  }

  100% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(93, 63, 211, 0);
  }
}

@keyframes pulse-jade {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(0, 163, 108, 0.5);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 163, 108, 0);
  }

  100% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(0, 163, 108, 0);
  }
}

@keyframes pulse-orange {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(255, 173, 5, 0.5);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 173, 5, 0);
  }

  100% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(255, 173, 5, 0);
  }
}

@keyframes pulse-lemon {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(252, 255, 75, 0.5);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(252, 255, 75, 0);
  }

  100% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(252, 255, 75, 0);
  }
}

@keyframes pulse-dark-blue {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(4, 67, 137, 0.5);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(4, 67, 137, 0);
  }

  100% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(4, 67, 137, 0);
  }
}

@keyframes pulse-pink {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(246, 82, 160, 0.5);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(246, 82, 160, 0);
  }

  100% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(246, 82, 160, 0);
  }
}

@keyframes pulse-cyan {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(54, 238, 224, 0.5);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(54, 238, 224, 0);
  }

  100% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(54, 238, 224, 0);
  }
}
