.tabs {
  display: flex;
  border-radius: 5px;
  border: 1px solid rgb(216, 216, 216);
  margin-bottom: 2rem;
}

.tab-item {
  cursor: pointer;
  text-align: center;
  padding: 0.5rem 0;
  border-radius: 5px;
}

.active-tab {
  background-color: #ffad05;
  color: black;
  font-weight: bold;
}
