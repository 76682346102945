/* html, body, #root {
  height: 100%;
} */
:root {
  --landing-bg-color: #eff4ff;
  --landing-footer-bg-color: #dce7ff;
}

#root {
  height: 100vh;
}

/* main {
  min-height: 80vh;
} */

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-left {
  justify-content: left;
}

.emphasis {
  font-weight: bold;
  color: black;
}

.m-0 {
  margin: 0;
}

.py-1 {
  padding-top: 1rem !important;
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 0.1rem 0 0.5rem 0; 
  text-align: center;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

a {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.hide {
  transform: translate(0, 0) scale(0, 0) !important;
  transition: all 0.5s;
}

.display-none {
  display: none;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left !important;
  justify-self: flex-start;
}

.tomato {
  background-color: tomato !important;
  border-color: #ff735a !important;
  color: white;
}

.tomato-text {
  color: tomato;
}

.blue {
  background-color: #0096ff !important;
}

.blue-text {
  cursor: pointer;
  color: #0096ff;
}

.blue-text:hover {
  color: #000000;
}

.grey {
  background-color: rgb(233, 233, 233) !important;
}

.iris {
  background-color: #5d3fd3 !important;
}

.jade {
  background-color: #00a36c !important;
}
.orange {
  background-color: #ffad05 !important;
}
.lemon {
  background-color: #fcff4b !important;
}
.dark-blue {
  background-color: #044389 !important;
}

.pink {
  background-color: #f652a0 !important;
}

.cyan {
  background-color: #36eee0 !important;
}

.green {
  background-color: #45d745 !important;
  border-color: #5eeb5e !important;
}

.flexbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-section {
  position: fixed;
  background: linear-gradient(
    0deg,
    /* rgba(1, 1, 1, 1) 40%, */ rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 0) 100%
  );
  bottom: 0;
  /*  height: 96px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 0 15px 2rem 15px;
}

.btn-inner-container {
  display: flex;
  width: 100%;
  max-width: 540px;
  justify-content: center;
}

.content-scroll {
  min-height: 0;

  padding-bottom: 96px;
}

.btn {
  border-radius: 8px;
  padding: 0.6rem 0;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  background-color: #00bfff;
  border: 3px solid #64d8ffa9;
  color: #fff;
  font-weight: bold;
  font-size: 1.1rem;
}

.btn-animation {
  width: 100%;
  animation-name: btnclick;
  animation-duration: 0.3s;
}

@keyframes btnclick {
  from {
    transform: scale(0.94, 0.97);
  }
  to {
    transform: scale(1, 1);
  }
}

.btn:hover {
  color: white !important;
  text-decoration: none;
  box-shadow: none;
}

.btn:focus {
  box-shadow: none;
  color: white !important;
}

.next-btn {
  margin-left: 10px;
}

.btn-secondary {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fc751b;
  border: 3px solid #ffa033;
  height: 3.1rem;
}

.btn-secondary-color {
  background-color: #fc751b;
  border: 3px solid #ffa033;
}
.btn-secondary-color:hover {
  background-color: #fc751b !important;
  border: 3px solid #ffa033 !important;
}

.btn-secondary:hover {
  background-color: #fc751b !important;
  border: 3px solid #ffa033 !important;
}

.settings-icon {
  font-size: 1.3rem;
}

.my-checkbox {
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: 0.5rem;
}

.flexbox-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexbox-align-items {
  align-items: center;
}

.profile-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 1.5rem 1.5rem;
  margin: 1.5rem 0;
  background: rgb(0, 212, 255);
  background: linear-gradient(
    167deg,
    rgba(0, 212, 255, 1) 0%,
    rgba(0, 212, 255, 1) 0%,
    rgba(132, 65, 255, 1) 100%
  );
}

.avatar {
  display: block;
  margin: 0 auto 0.9rem auto;
  border-radius: 50%;
  border: 3px solid white;
}

.profile-p {
  font-size: 1rem;
  font-weight: 400;
  color: white;
}

.logout-btn {
  margin-top: 1rem;
  border: 1px solid white;
  border-radius: 8px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: white;
}

.my-alert-wrapper {
  position: fixed;
  bottom: 80px;
  z-index: 100;
  width: 90%;
  max-width: 540px;
  left: 50%;
  transform: translateX(-50%);
}

/* .my-alert {
  position: fixed;
  bottom: 80px;
  z-index: 100;
  width: 90%;
  max-width: 540px;
  left: 50%;
  transform: translateX(-50%);
} */

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

.list-item-team {
  opacity: 0.45;
}

.list-item p {
  margin-bottom: 0px;
}
.list-item-round-result-p {
 /*  width: 237px; */
  width: 87%;
  overflow: hidden;
  /* word-break: break-all; */
}

.list-item-name {
  padding-right: 1.2rem;
}

.list-item-name:first-child {
  padding-left: 0;
}

.avatar-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.7rem;
  height: 3.7rem;
  border-radius: 50%;
  background-color: aqua;
  border: 3px solid lightcyan !important;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.current-queue-avatar {
  margin: 0 auto 1.5rem auto;
  width: 8rem;
  height: 8rem;
  z-index: 20;
}

.current-team {
  font-weight: bold;
  opacity: 1 !important;
}

.game-body {
  touch-action: none;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(0, 212, 255);
  background: linear-gradient(
    167deg,
    rgba(0, 212, 255, 1) 0%,
    rgba(0, 212, 255, 1) 0%,
    rgba(132, 65, 255, 1) 100%
  );
}

.yes-no-btn {
  width: 100%;
}

.pl10 {
  padding-left: 10px;
}

.noml {
  margin-left: 0px;
}

input[type="search"]::-webkit-search-cancel-button {
  /* Remove default */
  -webkit-appearance: none;

  /* Now your own custom styles */
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  /* setup all the background tweaks for our custom icon */
  background-repeat: no-repeat;

  /* icon size */
  background-size: 14px;
}

input[type="search"],
select:focus,
textarea {
  font-size: 16px;
}

.game-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.play-stop {
  cursor: pointer;
  font-size: 26px !important;
  color: white !important;
}

.score { font-size: 1.5rem}
.current-score { color: tomato;}

.pause-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
  width: 200px;
  height: 100px;
}

.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;
  height: 100vh;
  width: 70%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.pyro > .before,
.pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84,
    190px 16.33333px #002bff, -113px -308.66667px #ff009d,
    -109px -287.66667px #ffb300, -50px -313.66667px #ff006e,
    226px -31.66667px #ff4000, 180px -351.66667px #ff00d0,
    -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00,
    -69px -27.66667px #ff0400, -111px -339.66667px #6200ff,
    155px -237.66667px #00ddff, -152px -380.66667px #00ffd0,
    -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00,
    -88px 10.33333px #0d00ff, 112px -309.66667px #005eff,
    69px -415.66667px #ff00a6, 168px -100.66667px #ff004c,
    -244px 24.33333px #ff6600, 97px -325.66667px #ff0066,
    -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff,
    140px -196.66667px #9000ff, 125px -175.66667px #00bbff,
    118px -381.66667px #ff002f, 144px -111.66667px #ffae00,
    36px -78.66667px #f600ff, -63px -196.66667px #c800ff,
    -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff,
    -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2,
    91px -278.66667px #000dff, -22px -191.66667px #9dff00,
    139px -392.66667px #a6ff00, 56px -2.66667px #0099ff,
    -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb,
    -238px -346.66667px #00ff73, 62px -363.66667px #0088ff,
    244px -170.66667px #0062ff, 224px -142.66667px #b300ff,
    141px -208.66667px #9000ff, 211px -285.66667px #ff6600,
    181px -128.66667px #1e00ff, 90px -123.66667px #c800ff,
    189px 70.33333px #00ffc8, -18px -383.66667px #00ff33,
    100px -6.66667px #ff008c;
  -moz-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-moz-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-o-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-ms-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@media only screen and (max-width: 310px) { 
  .screen-header {
    font-size: 1.35rem;
  }
}

